import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>Logo</title>
    <g transform="translate(-8.000000, -2.000000)">
      <g transform="translate(11.000000, 5.000000)">
        <g
          id="B"
          transform="translate(26.000000, 24.000000) scale(.60 .60)"
          strokeLinecap="round"
          fillRule="evenodd"
          fontSize="9pt"
          stroke="#000"
          strokeWidth="0.25mm"
          fill="black">
          <path
            d="M 0 56.3 L 6 52.1 Q 7.746 55.195 9.87 56.936 A 10.877 10.877 0 0 0 11 57.75 A 11.593 11.593 0 0 0 17.068 59.498 A 13.863 13.863 0 0 0 17.3 59.5 A 13.722 13.722 0 0 0 20.349 59.183 Q 22.407 58.713 23.89 57.551 A 7.987 7.987 0 0 0 25.2 56.25 Q 27.8 53 27.8 45.5 L 27.8 0 L 36.2 0 L 36.2 46.3 A 30.092 30.092 0 0 1 35.559 52.588 A 27.171 27.171 0 0 1 35.15 54.25 A 20.136 20.136 0 0 1 33.326 58.714 A 17.55 17.55 0 0 1 31.95 60.8 A 14.532 14.532 0 0 1 27.037 64.863 A 17.226 17.226 0 0 1 26.35 65.2 A 16.504 16.504 0 0 1 22.368 66.437 Q 20.573 66.766 18.526 66.797 A 28.261 28.261 0 0 1 18.1 66.8 A 22.545 22.545 0 0 1 10.255 65.502 Q 4.42 63.352 0.792 57.637 A 25.458 25.458 0 0 1 0 56.3 Z"
            vectorEffect="non-scaling-stroke"
          />
        </g>
        <polygon
          id="Shape"
          stroke="currentColor"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
          points="39 0 0 22 0 67 39 90 78 68 78 23"
        />
      </g>
    </g>
  </svg>
);

export default IconLogo;
